import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { MarkdownProps } from './Markdown';

export const MarkdownContentWrapper = styled.div<
    Omit<MarkdownProps, 'markdown' | 'className'>
>(
    ({
        theme: { colors, fontWeight, fontSizes, letterSpacing },
        smallText,
        darkColor,
        aboutUs,
    }) => css`
        b {
            font-weight: ${fontWeight.bold};
        }
        strong {
            font-weight: ${fontWeight.bold};
        }

        p {
            color: ${colors.textSection};
            font-size: ${fontSizes.f18};
            font-weight: ${fontWeight.medium};
            letter-spacing: ${letterSpacing.s};
            line-height: 28px;

            ${mediaQueries.sm} {
                font-size: ${fontSizes.f14};
            }
        }
        ${smallText &&
        css`
            p {
                font-size: ${fontSizes.f16};
                color: ${darkColor ? colors.neutral500 : colors.neutral300};
                margin: 0 0 18px;
                :last-child {
                    margin: 0;
                }
            }
        `}

        ${aboutUs &&
        css`
            p {
                color: ${colors.white};
                font-weight: ${fontWeight.regular};
                margin: 0 0 18px;
                :last-child {
                    margin: 0;
                }

                ${mediaQueries.md} {
                    font-size: ${fontSizes.f16};
                }

                ${mediaQueries.sm} {
                    letter-spacing: ${letterSpacing.m};
                    line-height: 24px;
                }
            }

            b {
                font-weight: ${fontWeight.semiBold};
            }
            strong {
                font-weight: ${fontWeight.semiBold};
            }
        `}

        h2 {
            color: ${colors.textPrimary};
            font-size: ${fontSizes.f48};
            font-weight: ${fontWeight.semiBold};
            line-height: 64px;

            ${mediaQueries.sm} {
                font-size: ${fontSizes.f30};
                line-height: 36px;
            }
        }
    `,
);
