import React from 'react';
import ReactMarkdown from 'react-markdown';
import { MarkdownContentWrapper } from './Markdown.styled';
import raw from 'rehype-raw';

export interface MarkdownProps {
    className?: string;
    markdown: string;
    smallText?: boolean;
    darkColor?: boolean;
    aboutUs?: boolean;
}

export const Markdown: React.FC<MarkdownProps> = ({
    className,
    darkColor,
    markdown,
    smallText,
    aboutUs,
}) => {
    return (
        <MarkdownContentWrapper
            className={className}
            smallText={smallText}
            darkColor={darkColor}
            aboutUs={aboutUs}
        >
            <ReactMarkdown rehypePlugins={[raw]} children={markdown} />
        </MarkdownContentWrapper>
    );
};
